<template>
  <v-col cols="12">
    <v-card class="pa-2 main-card" outlined>
      <h2>Članstvo</h2>

      <v-col class="text-right">
        <!-- SAKRIVENO
        <router-link to="/members/new">
          <v-btn depressed color="primary" class="hide-from-print">
            <v-icon left> mdi-account-plus </v-icon>
            Unos novog člana
          </v-btn>
        </router-link>
         -->

        <TopMenu
          v-if="$refs.membersTable"
          :members="members"
          :tableItems="$refs.membersTable.$children[0].filteredItems"
        ></TopMenu>
      </v-col>

      <v-col class="text-right"> </v-col>

      <v-data-table
        :headers="headers"
        :items="members"
        :search="search"
        :loading="fetchingData"
        ref="membersTable"
        loading-text="Učitavam članove..."
        class="members-table"
      >
        <template v-slot:top>
          <v-row align="center">
            <v-col class="d-flex" cols="12" sm="8">
              <v-text-field
                v-model="search"
                label="Traži..."
                append-icon="mdi-magnify"
                single-line
                class="mx-4"
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="4">
              <v-select
                v-model="statusFilter"
                :items="['Svi', 'Aktivan', 'Neaktivan']"
                label="Status"
              ></v-select>
            </v-col>
          </v-row>
        </template>

        <template v-slot:[`item.LastName`]="{ item }">
          {{ item.LastName }}

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="item.Household_Role__c == 'Main'"
                v-bind="attrs"
                v-on="on"
                class="household-symbol"
              >
                mdi-account-star
              </v-icon>
            </template>
            <span>Nositelj obitelji</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.Active__c`]="{ item }">
          <span :class="`member-status ${item.Active__c}`">
            {{ memberStatus(item.Active__c) }}
          </span>
        </template>

        <template v-slot:[`item.Birthdate`]="{ item }">
          {{ formatDate(item.Birthdate) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn small depressed color="#fad390" @click="openMember(item.Id)">
            <v-icon small left> mdi-pencil </v-icon>
            Uredi
          </v-btn>

          <span></span>

          <div class="clearfix">
            <RenewMembershipButton
              v-if="!item.Active__c && item.Household_Role__c != 'Main'"
              :member="item"
              membershipType="single"
            />
          </div>

          <div class="clearfix">
            <RenewMembershipButton
              v-if="!item.Active__c && item.Household_Role__c == 'Main'"
              :member="item"
              membershipType="family"
            />
          </div>
        </template>
      </v-data-table>

      <p v-show="error" class="error-text">{{ error }}</p>

      <SearchMember />
    </v-card>
  </v-col>
</template>

<script>
import apiRequest from "../helpers/apiRequest";
import SearchMember from "../components/members/SearchMember.vue";
import RenewMembershipButton from "../components/membership/RenewMembershipButton.vue";
import TopMenu from "../components/members/TopMenu.vue";
import mixin from "../mixins/mixin";

export default {
  mixins: [mixin],

  name: "Members",

  components: {
    SearchMember,
    RenewMembershipButton,
    TopMenu,
  },

  data() {
    return {
      dialog: false,
      dialogDelete: false,
      fetchingData: false,
      search: "",
      members: [],
      error: false,
      statusFilter: "Svi",
    };
  },

  computed: {
    headers() {
      return [
        { text: "Ime", align: "start", value: "FirstName" },
        { text: "Prezime", value: "LastName" },
        { text: "Br. člana", value: "Member_ID__c" },
        {
          text: "Status",
          value: "Active__c",
          filter: (value) => {
            if (this.statusFilter == "Svi") {
              return true;
            }

            return this.memberStatus(value) == this.statusFilter;
          },
        },
        { text: "Datum rođenja", value: "Birthdate" },
        { text: "Telefon", value: "MobilePhone" },
        { text: "Zadnja g. članstva", value: "Last_Membership_Year__c" },
        {
          text: "Ukupno g. članstva",
          value: "Number_Of_Membership_Years__c",
        },
        { text: "Akcije", value: "actions", sortable: false },
      ];
    },
  },

  async created() {
    this.fetchMembers();
    this.$store.dispatch("fetchDphs");
  },

  methods: {
    async fetchMembers() {
      this.error = false;
      this.fetchingData = true;

      try {
        this.members = await apiRequest("get", "members");
      } catch (error) {
        this.error = error.message;
      } finally {
        this.fetchingData = false;
      }
    },

    openMember(id) {
      this.$router.push({ name: "member", params: { id } });
    },

    memberStatus(isActive) {
      return isActive ? "Aktivan" : "Neaktivan";
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  margin-bottom: 0;
}

@media only screen and (min-width: 583px) {
  button {
    margin: 0 5px 5px 0;
    &:first-child {
      margin-top: 5px;
    }
  }
}
</style>

<style lang="scss">
@media only screen and (min-width: 768px) {
  .members-table {
    table tr td:last-child {
      width: 20%;
    }
  }
}
</style>
