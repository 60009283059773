var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-2 main-card",attrs:{"outlined":""}},[_c('h2',[_vm._v("Članstvo")]),_c('v-col',{staticClass:"text-right"},[(_vm.$refs.membersTable)?_c('TopMenu',{attrs:{"members":_vm.members,"tableItems":_vm.$refs.membersTable.$children[0].filteredItems}}):_vm._e()],1),_c('v-col',{staticClass:"text-right"}),_c('v-data-table',{ref:"membersTable",staticClass:"members-table",attrs:{"headers":_vm.headers,"items":_vm.members,"search":_vm.search,"loading":_vm.fetchingData,"loading-text":"Učitavam članove..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"8"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Traži...","append-icon":"mdi-magnify","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":['Svi', 'Aktivan', 'Neaktivan'],"label":"Status"},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1)],1)]},proxy:true},{key:"item.LastName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.LastName)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.Household_Role__c == 'Main'),expression:"item.Household_Role__c == 'Main'"}],staticClass:"household-symbol"},'v-icon',attrs,false),on),[_vm._v(" mdi-account-star ")])]}}],null,true)},[_c('span',[_vm._v("Nositelj obitelji")])])]}},{key:"item.Active__c",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("member-status " + (item.Active__c))},[_vm._v(" "+_vm._s(_vm.memberStatus(item.Active__c))+" ")])]}},{key:"item.Birthdate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.Birthdate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","depressed":"","color":"#fad390"},on:{"click":function($event){return _vm.openMember(item.Id)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Uredi ")],1),_c('span'),_c('div',{staticClass:"clearfix"},[(!item.Active__c && item.Household_Role__c != 'Main')?_c('RenewMembershipButton',{attrs:{"member":item,"membershipType":"single"}}):_vm._e()],1),_c('div',{staticClass:"clearfix"},[(!item.Active__c && item.Household_Role__c == 'Main')?_c('RenewMembershipButton',{attrs:{"member":item,"membershipType":"family"}}):_vm._e()],1)]}}],null,true)}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"error-text"},[_vm._v(_vm._s(_vm.error))]),_c('SearchMember')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }